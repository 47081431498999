<template>
<error-loading v-if="error" />
  <b-row v-else class="match-height">
    <b-col :lg="cols_basic" sm="12">
      <twitch-basic
        :twitchData="data.report.basic"
        :subscribers="data.report.metrics.subscribers_count"
        :views="data.report.metrics.views_avg.performance.all"
      ></twitch-basic>
    </b-col>
    <b-col lg="6" sm="12" v-if="showEmbedTwitch">
      <b-card v-if="videoId">
        <iframe
          :src="`https://player.twitch.tv/?video=v${videoId}&parent=${host}&autoplay=false`"
          height="340"
          width="600"
          allowfullscreen="true">
      </iframe>
      </b-card>
    </b-col>
    <b-col cols="12">
      <twitch-metrics
        :twitchData="data.report.metrics"
      ></twitch-metrics>
    </b-col>
    <b-col cols="12">
      <age-gender
        v-if="data.report.features.audience_age_gender.data"
        :graphData="data.report.features.audience_age_gender.data ? data.report.features.audience_age_gender.data : {}"
      ></age-gender>
    </b-col>
    <b-col cols="12">
      <country-lang
      v-if="data.report.features.audience_languages.data"
      :languages="data.report.features.audience_languages.data"
      :countries="(data.report.features.audience_geo.data !== null) ? data.report.features.audience_geo.data.countries : []"/>
    </b-col>
    <b-col cols="12">
      <twitch-features
        :twitchData="data.report.features"
      ></twitch-features>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    TwitchBasic: () => import('@/views/pages/profile/twitch/TwitchBasic.vue'),
    TwitchMetrics: () => import('@/views/pages/profile/twitch/TwitchMetrics.vue'),
    TwitchFeatures: () => import('@/views/pages/profile/twitch/TwitchFeatures.vue'),
    AgeGender: () => import('../youtube/AgeGender.vue'),
    CountryLang: () => import('../youtube/CountryLang.vue'),
    ErrorLoading: () => import('../ErrorLoading.vue')
  },
  props: {
    data: {
      Type: Object,
      required: false
    },
    error: {
      Type: Boolean,
      required: true
    },
    showEmbedTwitch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    videoId() {
      if (this.data.report.features.stream_viewers.data.length === 0) return false
      return this.data.report.features.stream_viewers.data[0].video_id
    },
    host() {
      return window.location.host
    },
    cols_basic() {
      return this.showEmbedTwitch ? '6' : '12'
    }
  }
}
</script>
